<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import Gen from '@helper/Gen';

export default {
	name: "BoProfile",
	extends: Base,
  data(){
    return {
			Name:"BoProfile",
			mrLevel: {},
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
		this.refreshData(()=>{
      setTimeout(()=>{this.$set(this.row, 'type', 'update')},500)
    })
  },
  methods:{
    successFormCallback(resp){
			Gen.info(resp.message, "success", 3000)
			this.refreshUser()
    }
  },
  watch:{
    '$route.query'(v){
			this.refreshData()
		}
  }
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			FORM CRUD
		============================================================================= -->
		<div class="panel panel-flat">
			<VForm @resp="submitForm" method="post">
				<!-- <div class="panel-heading">
					<h2 class="panel-title">{{PageName}}</h2>
				</div> -->
				<div class="panel-body">
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="bu_full_name" v-model="row.bu_full_name"></BoField>
							<BoField name="bu_name" v-model="row.bu_name" keyFilter="usernameKey">
								<span slot="help" class="help-block">
									<small>{{ fields.bu_name }} for login to Backoffice page</small>
								</span>
							</BoField>
							<BoField name="bu_passwd" v-model="row.bu_passwd" :attr="{type:'password'}"></BoField>
						</div>

						<div class="col-sm-6">
							<BoField name="bu_pic" info="Rasio Ukuran 1:1, dengan ukuran max. 360x360">
								<Uploader name="bu_pic" type="users" uploadType="gallery" v-model="row.bu_pic"></Uploader>
							</BoField>
							<BoField name="bu_email" v-model="row.bu_email" :attr="{type:'email'}"></BoField>
							<BoField name="bu_mobile" v-model="row.bu_mobile"></BoField>
						</div>

						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
